import { Helmet } from 'react-helmet-async';
// sections
import { useAuthContext } from '../../../auth/hooks';
import { getRole } from '../../../auth/context/jwt/utils';
import TodayShippedView from 'src/sections/order/view/order-today-shipped-view';

// ----------------------------------------------------------------------
// Siparişler Yönlendirme
export default function  TodayShippedPage() {
  const { user, selectedCompany } = useAuthContext();
  const role = getRole();
  return (
    <>
      <Helmet>
        <title>Sevk Edilen Siparişler</title>
      </Helmet>

      <TodayShippedView
        user={role === 'company_user' ? user : undefined}
        company={selectedCompany ? selectedCompany : user?.Company?.[0]}
      />
    </>
  );
}
