// i18n
import 'src/locales/i18n';

// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// routes
import { ApolloProvider, gql, useQuery } from '@apollo/client';
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// locales
import { LocalizationProvider } from 'src/locales';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
// sections
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';
import { apolloClient } from './graphql/provider';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import 'instantsearch.css/themes/algolia.css';
import { useEffect } from 'react';
import { ConsoleProvider } from './utils/ConsoleProvider';
import { generateQueryOp } from './graphql/generated';
import { IMAGES } from './theme/image';
// ----------------------------------------------------------------------

// ... mevcut importlar ...
import { useMaintenanceSocket } from './hooks/use-maintenance-socket';
import { socket } from './utils/socket';

export default function App() {
  useScrollToTop();
  useMaintenanceSocket();
  
  useEffect(() => {
    const VERSION = process.env.REACT_APP_PROJECT_VERSION || '1.0.0';
    const storedVersion = window?.localStorage.getItem('VERSION');
    const storedTenant = window?.localStorage.getItem('TENANT');

    const faviconElement = document.getElementById('favicon') as HTMLLinkElement;
    if (faviconElement) {
      faviconElement.href = IMAGES.FAVICON;
    }

    if (storedVersion !== VERSION) {
      window.localStorage.clear();
      window.localStorage.setItem('VERSION', VERSION);
    }

    // Tenant sorgulama
    const { query, variables } = generateQueryOp({
      tenants: {
        __args: {
          where: {
            active: {
              equals: true,
            },
          },
        },
        id: true,
        company_name: true,
        active: true,
        config: true,
      },
    });

    (async () => {
      const { data } = await apolloClient.query({
        query: gql(query),
        variables,
      });
      
      if(!storedTenant) {
        window.localStorage.setItem('TENANT', JSON.stringify(data.tenants[0]));
      }
      
      document.title = data?.tenants?.[0]?.config?.company_short_name || 'BufiCore';
    })();

    // Component unmount olduğunda socket bağlantısını kapat
    return () => {
      if (socket.connected) {
        socket.disconnect();
      }
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
          <ApolloProvider client={apolloClient}>
            <LocalizationProvider>
              <SettingsProvider
                defaultSettings={{
                  themeMode: 'light',
                  themeDirection: 'ltr',
                  themeContrast: 'default',
                  themeLayout: 'vertical',
                  themeColorPresets: 'default',
                  themeStretch: false,
                }}
              >
                <ThemeProvider>
                  <MotionLazy>
                    <SnackbarProvider>
                      <SettingsDrawer />
                      <ProgressBar />
                      <AuthConsumer>
                        <ConsoleProvider>
                          <Router />
                        </ConsoleProvider>
                      </AuthConsumer>
                    </SnackbarProvider>
                  </MotionLazy>
                </ThemeProvider>
              </SettingsProvider>
            </LocalizationProvider>
          </ApolloProvider>
        </AuthProvider>
      </PersistGate>
    </Provider>
  );
}
