import { io } from 'socket.io-client';

export const socket = io(process.env.REACT_APP_HOST_API?.split('/api/v1')[0] , {
  transports: ['websocket']
});

// Debug için tüm eventleri dinle
socket.onAny((event, ...args) => {
});

// Temel event dinleyiciler
socket.on('connect', () => {
});

socket.on('connect_error', (error) => {
  console.error('Bağlantı hatası:', error);
});