import { format, parse } from 'date-fns';
import { tr } from 'date-fns/locale';
import moment from 'moment';

/**
 * Farklı formatlardaki tarih string'lerini Türkçe formatlı tarihe dönüştürür
 * @param date - Tarih string'i (/Date(timestamp)/ veya DD.MM.YYYY formatında)
 * @param formatPattern - İsteğe bağlı format paterni (varsayılan: 'dd MMMM yyyy')
 * @returns Formatlanmış tarih string'i veya hata durumunda '-'
 * 
 * @example
 * formatDate("/Date(1715380472223)/") // "12 Nisan 2024"
 * formatDate("25.04.2024") // "25 Nisan 2024"
 * formatDate(undefined) // "-"
 */
export const formatDate = (date: string | undefined, formatPattern: string = 'dd MMMM yyyy') => {
  if (!date) return '-';

  try {
    // /Date(timestamp)/ formatı için
    if (date.includes('/Date(')) {
      const timestampMatch = date.match(/\/Date\((\d+)\)\//);
      if (timestampMatch) {
        const timestamp = parseInt(timestampMatch[1], 10);
        return format(new Date(timestamp), formatPattern, { locale: tr });
      }
    }

    // ISO string formatı için (2024-07-01T00:00:00)
    if (date.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)) {
      const parsedDate = new Date(date);
      return format(parsedDate, formatPattern, { locale: tr });
    }

    // YYYY-MM-DD HH:mm:ss formatı için
    if (date.match(/^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}$/)) {
      const parsedDate = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());
      return format(parsedDate, formatPattern, { locale: tr });
    }

    // DD.MM.YYYY formatı için
    const parsedDate = moment(date, 'DD.MM.YYYY').toDate();
    return format(parsedDate, formatPattern, { locale: tr });
  } catch (error) {
    console.error('Tarih biçimlendirme hatası:', error);
    return '-';
  }
}; 