import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { socket } from '../utils/socket';

interface MaintenanceData {
  isActive: boolean;
  startTime: string;
  endTime: string;
  message: string;
}

export const useMaintenanceSocket = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Test event dinleyici
    socket.on('test', (data) => {
    });

    // Maintenance event dinleyici
    socket.on('maintenance', (data: MaintenanceData) => {
      if (data.isActive === true) {
        localStorage.clear();
        navigate('/auth/jwt/login?returnTo=%2F', { 
          state: { 
            message: data.message,
            maintenance: true,
            startTime: data.startTime,
            endTime: data.endTime,
            timestamp: new Date().toISOString()
          },
          replace: true
        });
      }
    });

    // Bağlantı kurulduğunda
    if (!socket.connected) {
      socket.connect();
    }

    // Test mesajı gönder
    socket.emit('test', { message: 'Test mesajı' });

    // Cleanup
    return () => {
      socket.off('test');
      socket.off('maintenance');
    };
  }, [navigate]);
};